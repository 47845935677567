import React from 'react';
import { withNamespaces } from 'react-i18next';
import cl from 'classnames';

import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import SiteTitle from '../PageHeaderTitle';
import { removeTags } from '../../utils/strings';

import s from './Application.module.scss';
import Link from '../Link';
import Api from '../../utils/api';

import img from '../../assets/img/applications/microsoft-surface-book.png';
import img2 from '../../assets/img/applications/microsoft-surface-book@2x.png';
import img3 from '../../assets/img/applications/microsoft-surface-book@3x.png';

import card7Img from '../../assets/img/applications/kill-switch.svg';
import card8Img from '../../assets/img/applications/dns.svg';
import card3Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card9Img from '../../assets/img/applications/connect.svg';
import card5Img from '../../assets/img/applications/global.svg';
import card6Img from '../../assets/img/applications/group-10.svg';

export const ApplicationWindows = withNamespaces()(({ t, brand, gatsbyUrl, pythonUrl, i18n }) => {
  const api = new Api({ gatsbyUrl, pythonUrl, i18n });

  return (
    <Application
      h1={t('page_applications:h1_win')}
      text={t('page_applications:text_1_win')}
      list={t('page_applications:list_win')}
      img={{ img, img2, img3 }}
      img_alt={t('page_applications:image_alt_windows')}
      img_title={`${brand.name} ${t('page_applications:image_alt_windows')}`}
    >
      <SiteTitle brandName={brand.name} title={removeTags(t('page_applications:h1_win'))} />
      <div className={s.buttons}>
        <Link className={cl(s.button)} to={api.applicationWindowsAppUrl()}>
          {t('page_applications:download_app')}
        </Link>
        <Link className={cl(s.button, s.button_dark)} to={api.applicationWindowsGuideUrl()}>
          {t('page_applications:setup_guides')}
        </Link>
      </div>
    </Application>
  );
});

export const ListOfFeaturesWindows = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_7_title'), text: t('page_applications:card_7_text'), imgSrc: card7Img },
    { title: t('page_applications:card_8_title'), text: t('page_applications:card_8_text'), imgSrc: card8Img },
    { title: t('page_applications:card_9_title'), text: t('page_applications:card_9_text'), imgSrc: card9Img },
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_5_text'), imgSrc: card5Img },
    { title: t('page_applications:card_3_title'), text: t('page_applications:card_3_text'), imgSrc: card3Img },
    { title: t('page_applications:card_6_title'), text: t('page_applications:card_6_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewWindows = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_4')}
    author={t('page_applications:anonymous')}
  />
));
